import React, { Component } from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"

class Footer extends Component {
  render() {
    return (
      <Wrapper>
        <Container fluid>
          <Row className="bg-black text-white footer">
            <Col
              md="8"
              className="d-flex flex-column justify-content-center pl-md-0"
            >
              <div>Ken Griffen</div>
              <div>
                Specializing in creative direction, art direction + branding /
              </div>
              <div>Based in New York</div>
            </Col>
            <Col
              md="4"
              className="d-flex flex-column justify-content-start text-left pl-md-0"
            >
              <div>
                <a href="mailto:ken@part02.com">ken@part02.com</a>
              </div>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div``

export default Footer
